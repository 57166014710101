import React from 'react';
import './styles/App.css';
import Waitlist from './components/Waitlist';
import InteractiveText from './components/InteractiveText';

function App() {
  return (
    <div className="App">
      <div className="content-container">
        <h1 className="title">spellr</h1>
        <p className="description">
          <InteractiveText text={"A complete phonics education that kids love."} />
          <InteractiveText text={"$80+/hr state-of-art tutoring, for 1% of the cost."} />
        </p>
        <div className="widget-container">
          <Waitlist />
        </div>
      </div>
    </div>
  );
}

export default App;