import '../styles/InteractiveText.css';
import React, { useState, useEffect } from 'react';
import { dictionary } from 'cmu-pronouncing-dictionary';

const arpabetToPhonetic = {
  "AA": "ah", "AE": "aeh", "AH": "uh", "AO": "aw", "AW": "ow",
  "AY": "ai", "B": "b", "CH": "ch", "D": "d", "DH": "th",
  "EH": "eh", "ER": "ur", "EY": "ey", "F": "f", "G": "g",
  "HH": "h", "IH": "ih", "IY": "ee", "JH": "j", "K": "k",
  "L": "l", "M": "m", "N": "n", "NG": "ng", "OW": "oh",
  "OY": "oy", "P": "p", "R": "r", "S": "s", "SH": "sh",
  "T": "t", "TH": "th", "UH": "uh", "UW": "oo", "V": "v",
  "W": "w", "Y": "y", "Z": "z", "ZH": "zh"
};

const getPhonetic = (word) => {
  if (word.match(/[0-9]/)) return word;

  const phones = dictionary[word.toLowerCase().replace(/[^a-z]/g, '')];
  if (!phones) return word;

  const arpa = phones.split(' ');

  return arpa.map((p) => {
    const phoneme = p.replace(/[0-2]/g, ''); // Remove stress markers
    const stress = p.match(/[0-2]/);
    const phonetic = arpabetToPhonetic[phoneme] || phoneme;
    if (stress && stress[0] === '1') {
      return <strong key={p}>{phonetic}</strong>;
    }
    return phonetic;
  }).reduce((prev, curr) => [prev, '-', curr]);
};

const InteractiveText = ({ text }) => {
  const [hoveredWordIndex, setHoveredWordIndex] = useState(null);
  const [phoneticMap, setPhoneticMap] = useState({});
  const [hoverEnabled, setHoverEnabled] = useState(true);

  useEffect(() => {
    const fetchPhonetics = (word) => {
      if (!phoneticMap[word]) {
        const phonetic = getPhonetic(word);
        setPhoneticMap(prevMap => ({
          ...prevMap,
          [word]: phonetic,
        }));
      }
    };

    text.split(' ').forEach(word => fetchPhonetics(word));
  }, [text, phoneticMap]);

  const handleMouseEnter = (index) => {
    if (hoverEnabled) {
      setHoveredWordIndex(index);
    }
  };

  const handleMouseLeave = () => {
    setHoveredWordIndex(null);
  };

  const words = text.split(' ').map((word, index) => {
    const phonetic = phoneticMap[word] || '';
    const isHoverable = phonetic !== word && hoverEnabled;
    const displayWord = hoveredWordIndex === index && isHoverable ? (
      <>
        {word}
        <span className="phoneme"> {phonetic}</span>
      </>
    ) : word;

    return (
      <span
        key={index}
        className={isHoverable ? "hoverable-word" : ""}
        onMouseEnter={isHoverable ? () => handleMouseEnter(index) : null}
        onMouseLeave={isHoverable ? handleMouseLeave : null}
      >
        {displayWord}
        {' '}
      </span>
    );
  });

  return (
    <div className="interactive-text">
      {words.reduce((prev, curr) => [prev, ' ', curr])}
    </div>
  );
};

export default InteractiveText;